<template>
	<w-section :title="$t('webdav-folder.preferences.title')">
		<w-text-info class="mb-2" :text="$t('webdav-folder.preferences.presentation_text')" />
		<w-layout row wrap>
			<w-flex v-if="isMobile" px-1 xs12 text-xs-left align-self-start font-italic my-3 v-text="'Exemple de résultat d\'affichage :'" />
			<w-flex v-if="isMobile" px-1 xs12 text-xs-left align-self-start font-weight-bold v-text="exempleDisplayResult"></w-flex>
			<w-flex :class="{'mt-3 mb-4': isMobile}" xs12 md4 align-self-center px-1>
				<SelectWithChips
					v-model="selectedItemsPreferences"
					class="ma-0 pa-0"
					:items="itemsPreferences"
					hide-details
					multiple
					:item-clearable="false"
					item-text="title"
					:max-selected-items-showed="10"
					:min-selected-items-preferences="1"
					:menu-props="{ closeOnContentClick: true }"
					@input="updateWebDavFolderPreferences()"
				/>
			</w-flex>
			<w-flex v-if="!isMobile" xs2 text-xs-center align-self-end font-italic class="ml-4">{{ $t("webdav-folder.preferences.display_example") }} :</w-flex>
			<w-flex v-if="!isMobile" xs4 text-xs-left align-self-end font-weight-bold v-text="exempleDisplayResult"></w-flex>
		</w-layout>
	</w-section>
</template>

<script>
import GlobalUserPreferencesService from '@/services/GlobalUserPreferences/GlobalUserPreferencesService'
import WebdavModuleGuard from "@/mixins/ModulesGuards/Webdav/WebdavModuleGuard";

export default {
	name: 'WebDavFolderPreferences',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		})
	},
	mixins: [WebdavModuleGuard],
	props: {},
	data: function () {
		return {
			itemsPreferences: [
				{
					'title': this.$t('webdav-folder.preferences.company_unique_identifier'),
					'value' : 'siret',
					'exemple': '90016482100016'
				},
				{
					'title': this.$t('webdav-folder.preferences.company_name'),
					'value' : 'name',
					'exemple': 'Entreprise ABC'
				},
				{
					'title': this.$t('webdav-folder.preferences.client_code'),
					'value' : 'client_code',
					'exemple': '897AEV'
				}],
			selectedItemsPreferences: [],
			globalUserPreference: null
		}
	},
	computed: {
		exempleDisplayResult: function () {
			let result = ""
			this.selectedItemsPreferences.forEach((aItem, index) => {
				let separator = (index === 0) ? '' : '-'
				let exemple = this.itemsPreferences.find(exemple => exemple.value === aItem).exemple
				result = result + separator + exemple
			})
			return result
		},
		isMobile () {
			return this.$vuetify.breakpoint.xsOnly;
		},
	},
	mounted: function () {
		this.getWebDavFolderPreferences()
	},
	methods: {
		getWebDavFolderPreferences: function () {
			GlobalUserPreferencesService.getGlobalUserPreference().then(globalUserPreference => {
				this.globalUserPreference = globalUserPreference
				const webdavPreferences = this.globalUserPreference.webdav_vendor_template.split('-')
				webdavPreferences.forEach(aWebDavPreference => {
					this.selectedItemsPreferences.push(aWebDavPreference)
				})
			})
		},
		updateWebDavFolderPreferences: function () {
			if (this.selectedItemsPreferences.length > 0) {
				const webdavTemplate = this.selectedItemsPreferences.join('-')
				GlobalUserPreferencesService.updateGlobalUserPreference(this.globalUserPreference.id, null, {'webdav_vendor_template': webdavTemplate}).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('webdav-folder.preferences.success'))
				})
			} else {
				this.selectedItemsPreferences.push(this.itemsPreferences.find(preference => preference.value === 'name').value)
			}

		}

	}
}
</script>
