import AbstractContextualizedModuleGuard from '@/mixins/ModulesGuards/AbstractContextualizedModuleGuard'
import { Events, Bus } from '@/events/Webdav/WebdavEvents'

export default {
	mixins: [AbstractContextualizedModuleGuard],
	data: function () {
		return {
			moduleName: 'webdav',
			eventBus: Bus,
			events: Events
		}
	}
}
