// ============================================================================
// GlobalUserPreferencesService
// -----------------------
// GlobalUserPreferences module related services
// ============================================================================
// -------
// Imports
// -------
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'
import API from '@/apis/GlobalUserPreferencesApi'
// ---------
// Internals
// ---------
const Private = {
    requests: {
        globalUserPreference: {
            create: NotImplementedYet,
            read: function (params = null) {
                return API.globalUserPreferences.read(params)
            },
            update: function (globalUserPreferenceId, params, data) {
                RequiredArguments({ globalUserPreferenceId: globalUserPreferenceId, data: data })
                return API.globalUserPreferences.update(globalUserPreferenceId, params, data)
            },
            delete: NotImplementedYet
        },
    },
    service: {
        globalUserPreference: {
            create: NotImplementedYet,
            read: function (params = null) {
                return Private.requests.globalUserPreference.read(params).then(globalUserPreference => {
                    return globalUserPreference
                })
            },
            update: function (globalUserPreferenceId, params = null, data) {
                return Private.requests.globalUserPreference.update(globalUserPreferenceId, params, data).then(updatedGlobalUserPreference => {
                    return updatedGlobalUserPreference
                })
            },
            delete: NotImplementedYet
        },
    },
    store: {}
}
// -------
// Exports
// -------
export default {
    getGlobalUserPreference: Private.service.globalUserPreference.read,
    updateGlobalUserPreference: Private.service.globalUserPreference.update,
}