// ============================================================================
// GlobalUserPreferencesApi
// -------------------
// GlobalUserPreferences api
// ============================================================================
// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'
import URLS from '@/apis/BackEndAPIEndpoints'
// -------
// Exports
// -------
export default {
	globalUserPreferences: {
        create: NotImplementedYet,
        read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
            RequiredArguments({})
            return Backend.GET(
                URLS.api.me.globalUserPreferences.uri,
                [],
                params,
                doCancelPreviousRequest,
                doCancelAllOtherRequests
            )
        },
        update: function (globalUserPreferenceId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
            RequiredArguments({ globalUserPreferenceId: globalUserPreferenceId,  data: data })
            return Backend.PATCH(
                URLS.api.me.globalUserPreferences.uri,
                [globalUserPreferenceId],
                params,
                data,
                doCancelPreviousRequest,
                doCancelAllOtherRequests
            )
        },
        delete: NotImplementedYet
    },
}